import styled from 'styled-components'

const Main = styled.main`
  margin: 3em 6em;

  @media (max-width: 768px) {
    margin: 1em;
  }
`

export default Main
