import React from 'react'
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby'

interface ImgFile {
  src: string
}

interface Props {
  className?: string
}

interface ImageProps {
  className?: string
  imgdata: Data
}

interface Data {
  file: {
    childImageSharp: {
      desktop: ImgFile
      desktop2x: ImgFile
      mobile: ImgFile
      mobile2x: ImgFile
      tablet: ImgFile
      tablet2x: ImgFile
    }
  }
}

const query = graphql`
  query {
    file(relativePath: {eq: "header.jpg"}) {
      childImageSharp {
        mobile: fixed(width: 480, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
        mobile2x: fixed(width: 960, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
        tablet: fixed(width: 768, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
        tablet2x: fixed(width: 1536, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
        desktop: fixed(width: 1200, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
        desktop2x: fixed(width: 2400, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

const Image = styled.div<ImageProps>`
  background-image: url(${(props) => props.imgdata.file.childImageSharp.desktop.src});
  background-position: center 0;
  background-size: cover;

  @media (max-width: 480px) {
    background-image: url(${(props) => props.imgdata.file.childImageSharp.mobile.src});
  }

  @media (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(${(props) => props.imgdata.file.childImageSharp.mobile2x.src});
  }

  @media (min-width: 481px) and (max-width: 768px) {
    background-image: url(${(props) => props.imgdata.file.childImageSharp.tablet.src});
  }

  @media (min-width: 481px) and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(${(props) => props.imgdata.file.childImageSharp.tablet2x.src});
  }

  @media (min-width: 769px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1201px) {
    background-image: url(${(props) => props.imgdata.file.childImageSharp.desktop2x.src});
  }
`

export default (({className, children}) => (
  <StaticQuery
    query={query}
    render={(data: Data) => (
      <Image className={className} imgdata={data}>
        {children}
      </Image>
    )}
  />
)) as React.FunctionComponent<Props>
