import React from 'react'
import styled from 'styled-components'
import PlaySvg from 'assets/play.svg'

interface AProps {
  coverUrl: string
}

interface Props {
  coverUrl: string
  videoUrl: string
}

const Svg = styled.svg`
  fill: white;
  opacity: 0.5;
  transition: opacity 0.2s linear;
  width: 100px;
`

const A = styled.a<AProps>`
  align-items: center;
  background-image: url(${({coverUrl}) => coverUrl});
  background-position: center center;
  background-size: auto 100%;
  display: flex;
  height: 250px;
  justify-content: center;
  transition: background-size 0.2s linear;

  &:hover {
    background-size: auto 115%;

    ${Svg} {
      opacity: 1;
    }
  }
`

const PortfolioItem: React.FunctionComponent<Props> = ({coverUrl, videoUrl}) => (
  <A coverUrl={coverUrl} href={videoUrl} target="_blank">
    <Svg>
      <use xlinkHref={`#${PlaySvg.id}`} />
    </Svg>
  </A>
)

export default PortfolioItem
