import React from 'react'
import styled from 'styled-components'
import Menu from './Menu'
import Logo from './Logo'
import SiteLink from './SiteLink'
import Image from './Image'

interface Props {
  text: string
}

const SmallLogo = styled(Logo)`
  height: 106px;
  width: 237px;

  @media (max-width: 768px) {
    height: auto;
    width: 80px;
  }
`

const ImageWithText = styled(Image)`
  align-items: center;
  display: flex;
  height: 300px;
  justify-content: center;
  margin: 3em 0 0;
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    height: 120px;
    margin-top: 0;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  right: 3em;
  top: 3em;

  @media (max-width: 768px) {
    right: 1em;
    top: 2em;
  }
`

const Text = styled.div`
  font-size: 40pt;

  @media (max-width: 768px) {
    font-size: 24pt;
  }
`

const Header: React.FunctionComponent<Props> = ({text}) => (
  <div>
    <Menu />
    <ImageWithText>
      <LogoWrapper>
        <SiteLink>
          <SmallLogo />
        </SiteLink>
      </LogoWrapper>
      <Text>{text}</Text>
    </ImageWithText>
  </div>
)

export default Header
