import React from 'react'
import Layout from 'components/layout/Layout'
import Main from 'components/layout/Main'
import Header from 'components/header/Header'
import PortfolioItemContainer from 'components/portfolio/PortfolioItemContainer'
import PortfolioItem from 'components/portfolio/PortfolioItem'

const PortfolioPage: React.FunctionComponent = () => (
  <Layout>
    <Header text="Портфолио" />
    <Main>
      <PortfolioItemContainer>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=rNqgHXHeCEI"
          coverUrl="http://i3.ytimg.com/vi/rNqgHXHeCEI/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=wTug0hlVks4"
          coverUrl="http://i3.ytimg.com/vi/wTug0hlVks4/hqdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=m7noaVLq32c"
          coverUrl="http://i3.ytimg.com/vi/m7noaVLq32c/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=kgnJZiYOVtM"
          coverUrl="http://i3.ytimg.com/vi/kgnJZiYOVtM/hqdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=aw81pWKLP1w"
          coverUrl="http://i3.ytimg.com/vi/aw81pWKLP1w/hqdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=7HmHclBLaq4"
          coverUrl="http://i3.ytimg.com/vi/7HmHclBLaq4/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=0y5_HCkUjAA"
          coverUrl="http://i3.ytimg.com/vi/0y5_HCkUjAA/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=Jyh-8tI8O8I"
          coverUrl="http://i3.ytimg.com/vi/Jyh-8tI8O8I/hqdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=JlQ_PBVCp7Q"
          coverUrl="http://i3.ytimg.com/vi/JlQ_PBVCp7Q/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=vh_HdjMTEmA"
          coverUrl="http://i3.ytimg.com/vi/vh_HdjMTEmA/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=pWXdbvX9CRY"
          coverUrl="http://i3.ytimg.com/vi/pWXdbvX9CRY/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=Hdj4KgAUBr8"
          coverUrl="http://i3.ytimg.com/vi/Hdj4KgAUBr8/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=_DU1OEvyKH4"
          coverUrl="http://i3.ytimg.com/vi/_DU1OEvyKH4/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=rRcFkTxeTFI"
          coverUrl="http://i3.ytimg.com/vi/rRcFkTxeTFI/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=GgvpDWF8NvY"
          coverUrl="http://i3.ytimg.com/vi/GgvpDWF8NvY/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=v1K5bjoQwms"
          coverUrl="http://i3.ytimg.com/vi/v1K5bjoQwms/maxresdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=_BACqSXg5x0"
          coverUrl="http://i3.ytimg.com/vi/_BACqSXg5x0/hqdefault.jpg"
        ></PortfolioItem>
        <PortfolioItem
          videoUrl="https://www.youtube.com/watch?v=D04SDLMMUOM"
          coverUrl="http://i3.ytimg.com/vi/D04SDLMMUOM/hqdefault.jpg"
        ></PortfolioItem>
      </PortfolioItemContainer>
    </Main>
  </Layout>
)

export default PortfolioPage
