import React from 'react'
import styled from 'styled-components'
import {Grid, Row, Col} from 'react-flexbox-grid'

const StyledGrid = styled(Grid)`
  max-width: 1200px;
  padding: 20px !important;
`

const StyledCol = styled(Col)`
  padding: 20px !important;
`

const PortfolioItemContainer: React.FunctionComponent = ({children}) => (
  <StyledGrid fluid>
    <Row>
      {React.Children.map(children, (x) => (
        <StyledCol xs={12} sm={6} md={6} lg={4}>
          {x}
        </StyledCol>
      ))}
    </Row>
  </StyledGrid>
)

export default PortfolioItemContainer
